import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import Loader from '../Loader/Loader';
import AppPaths from '../../Services/Utils/AppPaths';

import '../../Assets/Styles/styles.scss';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const Splash = lazy(() => import('../Splash/Splash'));
const Header = lazy(() => import('../../Layout/Header/Header'));
const Nav = lazy(() => import('../../Layout/Nav/Nav'));
const Start = lazy(() => import('../../Layout/Start/Start'));
const Facilities = lazy(() => import('../Facilities/Facilities'));
const Recreation = lazy(() => import('../../Layout/Recreation/Recreation'));
const Gastronomy = lazy(() => import('../Gastronomy/Gastronomy'));
const Services = lazy(() => import('../../Layout/Services/Services'));

/**
 * App component
 * @return {JSX.Element}
 * @constructor
 */

function App() {
	return (
		<BrowserRouter basename={process.env.REACT_APP_BASEURL}>
			<ScrollToTop />
			<CookiesProvider>
				<Suspense>
					<Header />
				</Suspense>
				<div className="container-fluid">
					<Routes>
						<Route index path="/" element={
							<Suspense fallback={<Loader />}>
								<Splash />
							</Suspense>
						} />
						<Route index path={AppPaths.home()} element={
							<Suspense fallback={<Loader />}>
								<Start />
							</Suspense>
						} />
						<Route index path={AppPaths.facilities()} element={
							<Suspense fallback={<Loader />}>
								<Facilities />
							</Suspense>
						} />
						<Route index path={AppPaths.recreation()} element={
							<Suspense fallback={<Loader />}>
								<Recreation />
							</Suspense>
						} />
						<Route index path={AppPaths.gastronomy()} element={
							<Suspense fallback={<Loader />}>
								<Gastronomy />
							</Suspense>
						} />
						<Route index path={AppPaths.services()} element={
							<Suspense fallback={<Loader />}>
								<Services />
							</Suspense>
						} />
						<Route index path="*" element={<Navigate to={AppPaths.home()} />} />
					</Routes>
				</div>
				<Suspense>
					<Nav />
				</Suspense>
			</CookiesProvider>
		</BrowserRouter>
	)
}

export default App;