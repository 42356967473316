const AppPaths = {
	
	/**
	 * Homepage path generator
	 * @return {"/inicio"}
	 */
	
	home          : () => '/inicio',
	
	/**
	 * Facilities path generator
	 * @return {"/instalaciones"}
	 */
	
	facilities    : () => '/instalaciones',
	
	/**
	 * Recreation path generator
	 * @param {Number} id
	 * @return {"/recreacion"|`/recreacion/${Number}`}
	 */
	
	recreation    : (id = null) => id !== null ? '/recreacion' : `/recreacion/${id}`,
	
	/**
	 * Gastronomy path generator
	 * @return {"/gastronomia"}
	 */
	
	gastronomy     : () => '/gastronomia',
	
	/**
	 * Restaurant menu path generator
	 * @return {"https://www.hotelnirvana.com"}
	 */
	
	restaurantMenu : () => 'https://www.hotelnirvana.com',
	
	/**
	 * Services path generator
	 * @return {"/servicios"}
	 */
	
	services       : () => '/servicios',
	
	/**
	 * Information path generator
	 * @return {"/informacion"}
	 */
	
	information    : () => '/informacion',
	
	/**
	 * Activities JSON path generator
	 * @return {"/activities.json?mode=${mode}&limit=${limit||-1}"}
	 */
	
	activitiesJSON : ({ mode, limit }) => `/activities.json?mode=${mode}&limit=${limit || -1}`,
	
	/**
	 * Rides JSON path generator
	 * @return {"/rides.json"}
	 */
	
	ridesJSON     : () => '/rides.json'
	
};

export default AppPaths;